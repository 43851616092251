.ql-undo {
  padding-top: 0 !important;
  padding-left: 6px;
}

.ql-redo {
  padding-top: 0 !important;
  padding-left: 2px;
}

.ql-undo:hover {
  filter: invert(35%) sepia(95%) saturate(4000%) hue-rotate(202deg)
    brightness(90%) contrast(90%);
}
.ql-redo:hover {
  filter: invert(35%) sepia(95%) saturate(4000%) hue-rotate(202deg)
    brightness(90%) contrast(90%);
}
